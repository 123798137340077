import { SwapERC20 } from "@airswap/libraries";
import { mainnets, OrderERC20, orderERC20ToParams } from "@airswap/utils";

import { ethers } from "ethers";

const shouldOverride = true;
const addressV4 = "0xd82FA167727a4dc6D6F55830A2c47aBbB4b3a0F8";
const contractV4Abi = [
  {
    inputs: [
      { internalType: "uint256", name: "_protocolFee", type: "uint256" },
      { internalType: "uint256", name: "_protocolFeeLight", type: "uint256" },
      { internalType: "address", name: "_protocolFeeWallet", type: "address" },
      { internalType: "uint256", name: "_rebateScale", type: "uint256" },
      { internalType: "uint256", name: "_rebateMax", type: "uint256" },
      { internalType: "address", name: "_staking", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "ChainIdChanged", type: "error" },
  { inputs: [], name: "InvalidFee", type: "error" },
  { inputs: [], name: "InvalidFeeLight", type: "error" },
  { inputs: [], name: "InvalidFeeWallet", type: "error" },
  { inputs: [], name: "InvalidStaking", type: "error" },
  { inputs: [], name: "MaxTooHigh", type: "error" },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "NonceAlreadyUsed",
    type: "error",
  },
  { inputs: [], name: "OrderExpired", type: "error" },
  { inputs: [], name: "ScaleTooHigh", type: "error" },
  { inputs: [], name: "SignatoryInvalid", type: "error" },
  { inputs: [], name: "SignatoryUnauthorized", type: "error" },
  { inputs: [], name: "SignatureInvalid", type: "error" },
  { inputs: [], name: "Unauthorized", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "signer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "signerWallet",
        type: "address",
      },
    ],
    name: "Authorize",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "signerWallet",
        type: "address",
      },
    ],
    name: "Cancel",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "signer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "signerWallet",
        type: "address",
      },
    ],
    name: "Revoke",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "protocolFee",
        type: "uint256",
      },
    ],
    name: "SetProtocolFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "protocolFeeLight",
        type: "uint256",
      },
    ],
    name: "SetProtocolFeeLight",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "feeWallet",
        type: "address",
      },
    ],
    name: "SetProtocolFeeWallet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "rebateMax",
        type: "uint256",
      },
    ],
    name: "SetRebateMax",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "rebateScale",
        type: "uint256",
      },
    ],
    name: "SetRebateScale",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "staking",
        type: "address",
      },
    ],
    name: "SetStaking",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "signerWallet",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "signerToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "signerAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "protocolFee",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "senderWallet",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "senderToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "senderAmount",
        type: "uint256",
      },
    ],
    name: "SwapERC20",
    type: "event",
  },
  {
    inputs: [],
    name: "DOMAIN_CHAIN_ID",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_NAME",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_VERSION",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "FEE_DIVISOR",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ORDER_TYPEHASH",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "signatory", type: "address" }],
    name: "authorize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "authorized",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "stakingBalance", type: "uint256" },
      { internalType: "uint256", name: "feeAmount", type: "uint256" },
    ],
    name: "calculateDiscount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "wallet", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "calculateProtocolFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256[]", name: "nonces", type: "uint256[]" }],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "senderWallet", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "address", name: "signerWallet", type: "address" },
      { internalType: "address", name: "signerToken", type: "address" },
      { internalType: "uint256", name: "signerAmount", type: "uint256" },
      { internalType: "address", name: "senderToken", type: "address" },
      { internalType: "uint256", name: "senderAmount", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "check",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "bytes32[]", name: "", type: "bytes32[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "signer", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
    ],
    name: "nonceUsed",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeLight",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeWallet",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rebateMax",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rebateScale",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revoke",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_protocolFee", type: "uint256" },
    ],
    name: "setProtocolFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_protocolFeeLight", type: "uint256" },
    ],
    name: "setProtocolFeeLight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_protocolFeeWallet", type: "address" },
    ],
    name: "setProtocolFeeWallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_rebateMax", type: "uint256" }],
    name: "setRebateMax",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_rebateScale", type: "uint256" },
    ],
    name: "setRebateScale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newstaking", type: "address" }],
    name: "setStaking",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "staking",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "address", name: "signerWallet", type: "address" },
      { internalType: "address", name: "signerToken", type: "address" },
      { internalType: "uint256", name: "signerAmount", type: "uint256" },
      { internalType: "address", name: "senderToken", type: "address" },
      { internalType: "uint256", name: "senderAmount", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "swap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "address", name: "signerWallet", type: "address" },
      { internalType: "address", name: "signerToken", type: "address" },
      { internalType: "uint256", name: "signerAmount", type: "uint256" },
      { internalType: "address", name: "senderToken", type: "address" },
      { internalType: "uint256", name: "senderAmount", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "swapAnySender",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "address", name: "signerWallet", type: "address" },
      { internalType: "address", name: "signerToken", type: "address" },
      { internalType: "uint256", name: "signerAmount", type: "uint256" },
      { internalType: "address", name: "senderToken", type: "address" },
      { internalType: "uint256", name: "senderAmount", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "swapLight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const getSwapErc20Contract = (
  providerOrSigner: ethers.providers.Provider | ethers.Signer,
  chainId: number
): ethers.Contract => {
  if (!shouldOverride || !mainnets.includes(chainId)) {
    return SwapERC20.getContract(providerOrSigner, chainId);
  }

  const address = addressV4;
  const contractInterface = new ethers.utils.Interface(contractV4Abi);

  return new ethers.Contract(address, contractInterface, providerOrSigner);
};

const isV4CheckResponse = (response: any): response is [number, string[]] => {
  return (
    Array.isArray(response) &&
    response.length === 2 &&
    Array.isArray(response[1])
  );
};

export const checkSwapErc20Order = async (
  providerOrSigner: ethers.providers.Provider,
  chainId: number,
  senderWallet: string,
  order: OrderERC20
): Promise<string[]> => {
  const contract = getSwapErc20Contract(providerOrSigner, chainId);

  const response = await contract.check(
    senderWallet,
    ...orderERC20ToParams(order)
  );

  if (isV4CheckResponse(response)) {
    return response[1];
  }

  return response;
};

export const getSwapErc20Address = (chainId: number): string | undefined => {
  if (!shouldOverride || chainId !== 1) {
    return SwapERC20.getAddress(chainId) || undefined;
  }

  return addressV4;
};
